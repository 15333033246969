<template>
    <div>
        <v-checkbox
            class="mt-0"
            :input-value="agree"
            @change="handleAgreement"
            color="primary"
            hide-details
        >
            <template v-slot:label>
                <p class="ml-2 py-0 my-0">
                    {{
                        portlandCity
                            ? $t("applications.rental-criteria-agree-pca")
                            : $t("applications.rental-criteria-agree")
                    }}
                </p>
            </template>
        </v-checkbox>

        <div class="ml-8">
            <h4 class="mt-3">
                {{ $t("applications.property-rental-criteria") }}
            </h4>
            <p class="ml-4 mt-2">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <a
                            v-on="on"
                            :href="property.criteria_url"
                            target="_blank"
                            >{{
                                $t("applications.view-rental-criteria", {
                                    company: $_.get(
                                        application,
                                        "company.name",
                                        ""
                                    ),
                                })
                            }}</a
                        >
                    </template>
                    <span>
                        {{ $t("applications.opens-in-new-window") }}
                    </span>
                </v-tooltip>
            </p>
            <div v-if="sections['rental-criteria-portland-city-notices']">
                <portland-city-notices></portland-city-notices>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "rental-criteria",
    components: {
        PortlandCityNotices: () => import("./PortlandCityNotices.vue"),
    },
    props: {
        applicant: {
            type: Object,
            required: true,
        },
        application: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            agree: this.$_.get(
                this.applicant,
                "authorizations.rental_criteria_agree"
            ),
        };
    },
    computed: {
        sections() {
            return this.applicant.sections;
        },
        property() {
            return this.application.property;
        },
        portlandCity() {
            return this.$_.get(
                this.application,
                "property.flags.portland_city_application"
            );
        },
    },
    watch: {
        applicant: {
            immediate: true,
            handler(applicant) {
                if (applicant.authorizations.rental_criteria_agree) {
                    this.$emit("update", {
                        complete: true,
                    });
                } else {
                    this.$emit("update", {
                        complete: false,
                    });
                }
            },
        },
    },
    methods: {
        handleAgreement(agree) {
            this.$emit("update", {
                applicant: {
                    authorizations: Object.assign(
                        this.applicant.authorizations,
                        {
                            rental_criteria_agree: agree,
                        }
                    ),
                },
            });
        },
    },
};
</script>

<style scoped>
</style>