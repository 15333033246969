var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-checkbox", {
        staticClass: "mt-0",
        attrs: {
          "input-value": _vm.agree,
          color: "primary",
          "hide-details": ""
        },
        on: { change: _vm.handleAgreement },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function() {
              return [
                _c("p", { staticClass: "ml-2 py-0 my-0" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.portlandCity
                          ? _vm.$t("applications.rental-criteria-agree-pca")
                          : _vm.$t("applications.rental-criteria-agree")
                      ) +
                      " "
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("div", { staticClass: "ml-8" }, [
        _c("h4", { staticClass: "mt-3" }, [
          _vm._v(
            " " + _vm._s(_vm.$t("applications.property-rental-criteria")) + " "
          )
        ]),
        _c(
          "p",
          { staticClass: "ml-4 mt-2" },
          [
            _c(
              "v-tooltip",
              {
                attrs: { top: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "a",
                          _vm._g(
                            {
                              attrs: {
                                href: _vm.property.criteria_url,
                                target: "_blank"
                              }
                            },
                            on
                          ),
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("applications.view-rental-criteria", {
                                  company: _vm.$_.get(
                                    _vm.application,
                                    "company.name",
                                    ""
                                  )
                                })
                              )
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              },
              [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("applications.opens-in-new-window")) +
                      " "
                  )
                ])
              ]
            )
          ],
          1
        ),
        _vm.sections["rental-criteria-portland-city-notices"]
          ? _c("div", [_c("portland-city-notices")], 1)
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }